import {graphql, Link} from 'gatsby'
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import styled from 'styled-components'
import _ from 'lodash'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

import Layout from '../components/layout'
import Seo from '../components/seo'
import {media} from '../components/layout'
import coins from '../data/data_handler'


axios.defaults.baseURL = 'http://localhost:8000'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'


export default ({pageContext: {coin, chart, exchanges}}) => {

    const meta = {
        title: `${coin['name']}`,
        description: `${coin['name']}の特徴や概要、取り扱いのある仮想通貨取引所の情報などを初心者の方にもわかりやすくまとめました。`
    }
    const endpoint = 'https://fk100rsrz9.execute-api.us-west-2.amazonaws.com/api'

    let summaryDisplay = {}
    coin['summary'].map(item => summaryDisplay[item['key']] = false)
    const [showSummary, setShowSummary] = useState(summaryDisplay)

    const exchange_list = []
    _.map(exchanges, exchange => {
        if (exchange['coins'].includes(coin['symbol'])) {
            exchange_list.push(exchange)
        }
    })

    const [bitbankTicker, setBitbankTicker] = useState({})
    const [bitflyerTicker, setBitflyerTicker] = useState({})
    const [bitpointTicker, setBitpointTicker] = useState({})
    const [coincheckTicker, setCoincheckTicker] = useState({})
    const [decurretTicker, setDecurretTicker] = useState({})
    const [dmmTicker, setDmmTicker] = useState({})
    const [gmoTicker, setGmoTicker] = useState({})
    const [huobiTicker, setHuobiTicker] = useState({})
    const [liquidTicker, setLiquidTicker] = useState({})
    const [sbiTicker, setSbiTicker] = useState({})

    const tickers = {
        bitbank: {
            value: bitbankTicker,
            func: setBitbankTicker
        },
        bitflyer: {
            value: bitflyerTicker,
            func: setBitflyerTicker
        },
        bitpoint: {
            value: bitpointTicker,
            func: setBitpointTicker
        },
        coincheck: {
            value: coincheckTicker,
            func: setCoincheckTicker
        },
        decurret: {
            value: decurretTicker,
            func: setDecurretTicker
        },
        dmm: {
            value: dmmTicker,
            func: setDmmTicker
        },
        gmo: {
            value: gmoTicker,
            func: setGmoTicker
        },
        huobi: {
            value: huobiTicker,
            func: setHuobiTicker
        },
        liquid: {
            value: liquidTicker,
            func: setLiquidTicker
        },
        sbi: {
            value: sbiTicker,
            func: setSbiTicker
        },
    }

    useEffect(() => {
        const getTicker = async (exchange) => {
            try {
                const res = await axios.get(`${endpoint}/${exchange}/${coin['symbol']}`)
                tickers[exchange]['func'](res.data)
            } catch (e) {
                console.log('ERROR | REST API FETCH for : ', exchange)
                console.log(e)
            }
        }
        exchange_list.map(exchange => {
            if (exchange['api']) {
                getTicker(exchange['api'])
            }
        })
    }, [])


    const Header = () => (
        <HeaderWrapper>
            <div>
                <Logo>
                    <img src={`/images/coin_icons/${coin['icon_large']}`} alt={coin['name']}/>
                </Logo>
                <Coin>
                    <CoinName>
                        <h2>{coin['jpname']}</h2>
                        <div>{coin['name']} - <span>{coin['symbol']}</span></div>
                    </CoinName>
                    <div>
                        <ResponsiveContainer width='90%' height={200}>
                            <LineChart data={chart}>
                                <XAxis dataKey='date' hide={true}/>
                                <Tooltip/>
                                <Line type='monotone' dataKey='close' stroke={coin['color']} dot={false}
                                      strokeWidth={3}/>
                            </LineChart>
                        </ResponsiveContainer>
                        <RateWrapper>
                            <Rate></Rate>
                            <Percent color='#03A66D'></Percent>
                        </RateWrapper>
                    </div>
                </Coin>
            </div>
        </HeaderWrapper>
    )

    const Summary = () =>
        <SummaryWrapper>
            <SectionHeader>
                <SectionHeaderIcon url={`/images/coin_icons/${coin['icon_large']}`}/>
                <h2>{coin['jpname']}の特徴</h2>
            </SectionHeader>
            <SummaryItems>
                {coin['summary'].map(item =>
                    <SummaryItem>
                        <DoneOutlineIcon/>
                        <span>{item['short']}</span>
                        <div onClick={() => setShowSummary({
                            ...showSummary,
                            [item['key']]: !showSummary[item['key']]
                        })}>
                            {showSummary[item['key']] ? <RemoveCircleOutlineIcon/> : <AddCircleOutlineIcon/>}
                        </div>
                        {/*{showSummary[item['key']] ? <p>{item['long']}</p> : ''}*/}
                        {showSummary[item['key']] ? item['long'].map(p => <p>{p}</p>) : ''}
                    </SummaryItem>
                )}
            </SummaryItems>
        </SummaryWrapper>

    const Infobox = () =>
        <InfoboxWrapper>
            <InfoboxTable>
                <div>
                    <span>通貨記号 :</span>
                    <span style={{textTransform: 'uppercase'}}>{coin['symbol']}</span>
                </div>
                <div>
                    <span>発行上限 :</span>
                    <span>{coin['circulation'] > 0 ? coin['circulation'] : ''}</span>
                </div>
                <div>
                    <span>ハッシュアルゴリズム :</span>
                    <span>{coin['hash']}</span>
                </div>
                <div>
                    <span>承認方式 :</span>
                    <span>{coin['scheme']}</span>
                </div>
                <div>
                    <span>発行日 :</span>
                    <span>{coin['issued']}</span>
                </div>
            </InfoboxTable>

            <InfoboxLinksAndTags>
                <div>
                    <a href={coin['website']} target="_blank" rel="noopener noreferrer">ウェブサイト</a>
                    <a href={coin['code']} target="_blank" rel="noopener noreferrer">ソースコード</a>
                    <a href={coin['white_paper']} target="_blank" rel="noopener noreferrer">ホワイトペーパー</a>
                    <a href={coin['coinmarketcap']} target="_blank" rel="noopener noreferrer">CoinMarketCap</a>
                </div>
                <div className='graybg'>
                    <a href='#'>PoW</a>
                    <a href='#'>SHA-256</a>
                    <a href='#'>マイニング</a>
                    <a href='#'>ブロックチェーン</a>
                </div>

            </InfoboxLinksAndTags>

            <InfoboxDescription>
                <p>{coin['description']}</p>
                <div><a href={coin['wikipedia']}>ウィキペディア</a></div>
            </InfoboxDescription>
        </InfoboxWrapper>

    const Exchange = () =>
        <ExchangeWrapper>
            <SectionHeader>
                <SectionHeaderIcon url={`/images/coin_icons/${coin['icon_large']}`}/>
                <h2>{coin['jpname']}の取り扱いがある取引所</h2>
            </SectionHeader>
            <ExchangeItems>
                <ExchangeHeader>
                    <span></span>
                    <span className='name'>取引所</span>
                    <span>価格</span>
                    <span>高値</span>
                    <span>安値</span>
                    <span>出来高</span>
                </ExchangeHeader>

                {exchange_list.map(exchange => {
                    if (exchange['value'] === 'dmmbitcoin') {
                        return (
                            <ExchangeItem>
                                <a href={exchange['url']} target='_blank' rel='noopener noreferrer'>
                                    <img src={`/images/exchange_icons/${exchange['icon']}`} alt={exchange['name']}/>
                                </a>
                                <span className='name'>
                                <a href={exchange['url']} target='_blank'
                                   rel='noopener noreferrer'>{exchange['label']}</a>
                                </span>
                            </ExchangeItem>
                        )
                    }
                    const ticker = tickers[exchange['api']]
                    if (!ticker) {
                        return
                    }
                    return (
                        <ExchangeItem>
                            <a href={exchange['url']} target='_blank' rel='noopener noreferrer'>
                                <img src={`/images/exchange_icons/${exchange['icon']}`} alt={exchange['name']}/>
                            </a>
                            <span className='name'>
                                <a href={exchange['url']} target='_blank'
                                   rel='noopener noreferrer'>{exchange['label']}</a>
                            </span>
                            <span>{ticker['value']['last'] ? ticker['value']['last'] : ''}</span>
                            <span>{ticker['value']['high'] ? ticker['value']['high'] : ''}</span>
                            <span>{ticker['value']['low'] ? ticker['value']['low'] : ''}</span>
                            <span>{ticker['value']['volume'] ? parseFloat(ticker['value']['volume']).toFixed(2) : ''}</span>
                        </ExchangeItem>
                    )
                })}

            </ExchangeItems>
        </ExchangeWrapper>

    const RelatedCoins = () =>
        <GrayBackground>
            <SectionHeader>
                <SectionHeaderIcon url={`/images/coin_icons/${coin['icon_large']}`}/>
                <h2>{coin['jpname']}に関連のある仮想通貨</h2>
            </SectionHeader>
            <RelatedCoinItems>
                {
                    _.map(coins, coin =>
                        <Link to={`/coins/${coin['symbol']}`}>
                            <RelatedCoin>
                                <img src={`/images/coin_icons/${coin['icon']}`} alt={coin['name']}/>
                                <div><p>{coin['symbol']}</p></div>
                            </RelatedCoin>
                        </Link>
                    )
                }
            </RelatedCoinItems>
        </GrayBackground>

    return (
        <Layout>
            <Seo title={meta['title']} description={meta['description']}/>
            <Wrapper>
                <Header/>
                <Infobox/>
                <Summary/>
                <Exchange/>
                <RelatedCoins/>
            </Wrapper>
        </Layout>
    )
}


// Style +++++++++++++++++++++++++++++++++++++++++++++++++++

// Common -------------------------------------------------

const Wrapper = styled.div`
    display: grid;
`

const GrayBackground = styled.div`
    background-color: #fafafa;
`

const Frame = styled.section`
    border-radius: 0.625vw;
    padding: calc(( 65 / 1280 ) *100vw ) calc(( 40 / 1280 ) *100vw );
    width: calc(( 1000 / 1280 ) *100vw );
    max-width: 1000px;
    margin: calc(( 75 / 1280 ) *100vw ) auto 0 auto;
    background-color: #fff;
`

const HalfFrame = styled(Frame)`
    width: calc(( 420 / 1280 ) *100vw );
    max-width: 420px;
`

const SectionHeader = styled.div`
    position: relative;
    border: solid 0px #ccc;
    text-align: center;
    padding: 150px 0 20px 0;
    margin-top: 20px;
    
    h2 {
        font-weight: 700;
        font-size: 2.4rem;
        margin: 0;
        padding: 0 20px;
        background: transparent;
    }
`

const SectionHeaderIcon = styled.div`
    background: url(${(props) => props.url});
    background-size: contain;
    
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    width: 130px;
    height: 130px;
    
    svg {
        font-size: 5.5rem;
    }
`


// Exchange ----------------------------------------------------

const ExchangeWrapper = styled.div`
`

const ExchangeItems = styled(Frame)`
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: 0 auto 0 auto;
    
    ${media.lessThanLarge`
        overflow: auto;    
    `}
`

const ExchangeHeader = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr;
    background-color: #fafafa;
    padding: 12px 24px; 
    border-radius: 8px;
    
    span {
        justify-self: center;
    }
    
    .name {
        justify-self: start;
    }
`

const ExchangeItem = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    padding: 5px 20px;
    border-bottom: solid 1px #ddd;
    font-size: 14px;

    img {
        height: 40px;
        margin: 0;
    }

    span {
        align-self: center;
        justify-self: end;
        font-weight: 600;
    }

    .name {
        justify-self: start;
        margin-left: 10px;
        font-weight: bold;
    }
`


// Summary -----------------------------------------------------

const SummaryWrapper = styled(GrayBackground)`  
`

const SummaryItems = styled(Frame)`
    margin: 0 auto 0 auto;
    background-color: transparent;
`

const SummaryItem = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 60px auto 60px;
    row-gap: 10px;
    column-gap: 20px;
    padding: calc(( 20 / 1280 ) *100vw ) calc(( 40 / 1280 ) *100vw );
    margin: 0 0 25px 0;
    background-color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
    border-radius: 10px;
    
    p {
        grid-column: 2/3;
        font-size: 1rem;
        
        ${media.lessThanLarge`
            grid-column: 1/4;
            padding: 20px;
        `}
    }
    
    svg {
        font-size: 36px;
        color: rgb(255, 165, 0);
    }
`

// Infobox -------------------------------------------------

const InfoboxWrapper = styled(Frame)`
    display: grid;
    margin: 0 auto 0 auto;
    padding: calc(( 65 / 1280 ) *100vw ) calc(( 0 / 1280 ) *100vw );
    
    ${media.lessThanLarge`
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        row-gap: 20px;
    `} 
    
    ${media.large`
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        column-gap: 20px;
        row-gap: 40px;
    `}
`

const InfoboxTable = styled(HalfFrame)`
    grid-column: 1/2;
    margin: 0 auto 0 auto;
    padding: calc(( 65 / 1280 ) *100vw ) calc(( 30 / 1280 ) *100vw );
    border: solid calc((100vw / 750 ) * 8 ) #e6e6e6;
    border-radius: 8px;
    border-width: 4px;
    
    ${media.lessThanLarge`
        width: calc(( 900 / 1280 ) *100vw );
        max-width: 1000px;
        grid-row: 1;
        grid-column: 1;
    `} 

    > div {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 20px;
        padding: 10px;
        word-break: break-all;
    }
    
    > a {
        display: inline-block;
        margin: 10px 0 0 10px;
        padding: 5px 15px;
        border-radius: 25px;
        font-size: 10px;
        text-decoration: none;
        color: #333;
        background-color: #e6e6e6;
        // background-image: linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        // transition: .4s;
    }
`

const InfoboxDescription = styled(Frame)`
    grid-row: 2/3;
    grid-column: 1/3;
    margin: 0 auto 0 auto;
    padding: calc(( 40 / 1280 ) *100vw ) calc(( 30 / 1280 ) *100vw );
    border: solid calc((100vw / 750 ) * 8 ) #e6e6e6;
    border-radius: 8px;
    border-width: 4px;
    width: calc(( 900 / 1280 ) *100vw );
    max-width: 900px;
    
    ${media.lessThanLarge`
        grid-row: 3;
        grid-column: 1;
    `} 
    
    div {
        display: grid;
    }
    
    a {
        justify-self: end;
        text-decoration: none;
        color: #1a0dab;
    }
`

const InfoboxLinksAndTags = styled(HalfFrame)`
    grid-column: 2/3;
    margin: 0 auto 0 auto;
    padding: calc(( 65 / 1280 ) *100vw ) calc(( 30 / 1280 ) *100vw );
    border: solid calc((100vw / 750 ) * 8 ) #e6e6e6;
    border-radius: 8px;
    border-width: 4px;
    
    ${media.lessThanLarge`
        width: calc(( 900 / 1280 ) *100vw );
        max-width: 1000px;
        grid-row: 2;
        grid-column: 1;
    `} 
    
    > div {
        padding: 20px 0;
    }
    
    > div a {
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 7px 20px;
        border-radius: 25px;
        font-size: 12px;
        text-decoration: none;
        color: #FFF;
        background: rgb(255,165,0);
        // background-image: linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        transition: .4s;
    }
    .graybg a {
        color: #333;
        background: #ececec;
    }
    
    .graybg {  
    }
    
`

// Header --------------------------------------------------

const HeaderWrapper = styled.header`
    margin: 5px 0;
    background-color: #fafafa;
    
    ${media.large`
        > div {
            position: relative;
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            justify-content: center;
            padding: 0;
            height: calc(( 375 / 1280 ) *100vw );
            max-height: 375px;
            width: calc(( 1000 / 1280 ) *100vw );
            max-width: 1000px;
            margin: 0 auto;
            z-index: 1;
        }
    `}
    
    ${media.lessThanLarge`
        > div {
            display: grid;
            justify-content: center;
        }
    `}
    
`

const Logo = styled.figure`
    width: calc(( 300 / 1280 ) *100vw );
    height: auto;
    max-width: 300px;
    text-align: center;
    margin: 0;
    
    ${media.lessThanLarge`
        width: calc((100vw / 750 ) * 300 );
        margin: calc((100vw / 750 ) * 40 ) auto 10px;
    `} 
    
`

const Coin = styled.div`
    display: grid;
    // grid-template-columns: auto auto;
    border-radius: 8px;
    background-color: #fff;
    -webkit-box-shadow: 8px 8px 0 #d9d9d9;
    box-shadow: 8px 8px 0 #d9d9d9;
    text-align: left;
    
    ${media.large`
        width: calc(( 500 / 1280 ) *100vw );
        padding: calc(( 30 / 1280 ) *100vw ) calc(( 30 / 1280 ) *100vw ) calc(( 30 / 1280 ) *100vw ) calc(( 45 / 1280 ) *100vw );
        max-width: 500px;
        margin: 0 auto 0 0; 
    `}
     
     ${media.lessThanLarge`
        width: calc(( 900 / 1280 ) *100vw );
        margin: 0 calc((100vw / 750 ) * 40 ) 20px;
        padding: calc((100vw / 750 ) * 30 );
    `} 
`

const CoinName = styled.div`
    > div {
        padding-left: 10px;
        font-size: 14px;
        color: #888;
    }
    
     h2 {
        margin: 0 0 5px;
    }
    
    span {
        text-transform: uppercase;
    }
`

const RateWrapper = styled.div`
    display: grid;
    margin-top: -80px;
    
    align-items: end;
    justify-items: end;
    text-align: right;
    padding: 0 20px;
`

const Rate = styled.div`
    font-size: 16px;
    font-weight: bold;
`

const Percent = styled.div`
    font-size: 12px;
    color: ${(props) => props.color};
`

// Related Coins ------------------------------------------

const RelatedCoinItems = styled(Frame)`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    margin: 0 auto 0 auto;
    background-color: transparent;
`

const RelatedCoin = styled.div`
    width: 5vw;
    font-size: calc(( 12 / 1280 ) *100vw );
    text-transform: uppercase;
    
    img {
        width: 100%
        padding: 0;
        margin: 0;
        // border: solid calc((100vw / 750 ) * 2 ) #d9d9d9;
        // border-radius: 10px;
        // border-width: 1px;
    }
    
    div {
        display: grid;
        justify-items: center;
    }
`

// Qraphql +++++++++++++++++++++++++++++++++++++++++++++++++
export const query = graphql`
  {
    allCoinsJson {
      edges {
        node {
          btc {
            symbol
            name
            jpname
            icon
            icon_large
            related
            color
            circulation
            hash
            scheme
            issued
            website
            code
            white_paper
            coinmarketcap
            description
            summary {
                key
                short
                long
            }
          }
        }
      }
    }
    allExchangesJson {
        edges {
          node {
            coins
            jpname
            label
            icon
            url
            value
          }
        }
      }
  }
`
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++
